<template>
  <div class="payment payment-completed">
    <v-container>
      <PaymentStepper :step="3" class="mb-2 mt-1 mt-sm-0" />
    </v-container>
    <div
      class="d-flex align-center flex-column justify-center flex-wrap title-wrap px-2"
    >
      <h2 v-if="paymentFailed" class="mb-10 ko text-center">
        {{ $t("paymentCompleted.failure.title") }}
      </h2>
      <h2 v-else class="ok">
        {{ $t("paymentCompleted.success.title") }}
      </h2>
    </div>

    <v-card elevation="0" class="card text-center mx-auto">
      <!-- pagamento fallito -->
      <v-card-text v-if="paymentFailed">
        <h3>
          {{ $t("paymentCompleted.failure.messageStart") }}
          <router-link
            style="text-decoration: none;"
            :to="{ name: 'Order', params: { orderId: order.orderId } }"
            >{{ order.orderId }}</router-link
          >
          {{ $t("paymentCompleted.failure.messageEnd") }}
          <br />
          {{ $t("paymentCompleted.failure.retry") }}
        </h3>
      </v-card-text>

      <!-- pagamento completato orderStatusId 3 -->
      <v-card-text v-if="!paymentFailed && order.orderStatusId === 3">
        <!-- deliveryServiceId 1 -->
        <template
          v-if="
            order.shippingAddress.deliveryServiceId === 1 ||
              order.shippingAddress.deliveryServiceId === 4
          "
        >
          <h3>
            {{
              $t(
                `paymentCompleted.deliveryService.${order.shippingAddress.deliveryServiceId}.title`
              )
            }}
          </h3>
          <div
            color="primary"
            class="link primary lighten-1 text-h5 font-weight-bold my-7 text-center rounded-sm d-inline-block pa-3"
          >
            <router-link
              class="no-underline white--text"
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >{{ `N. ${order.orderId}` }}</router-link
            >
          </div>
        </template>
        <!-- deliveryServiceId 2 oppure 6 -->
        <template
          v-else-if="
            order.shippingAddress.deliveryServiceId === 2 ||
              order.shippingAddress.deliveryServiceId === 6
          "
        >
          <h3>
            {{ $t("paymentCompleted.deliveryService.2.title") }}
          </h3>
          <div
            color="primary"
            class="link primary lighten-1 text-h5 font-weight-bold my-7 text-center rounded-sm d-inline-block pa-3"
          >
            <router-link
              class="no-underline white--text"
              :to="{ name: 'Order', params: { orderId: order.orderId } }"
              >{{ `N. ${order.orderId}` }}</router-link
            >
          </div>

          <p class="text-h5 grey--text text--darken-2">
            {{ $t("paymentCompleted.deliveryService.2.selectedPoint") }}
            <strong>
              {{
                $t(
                  "navbar.address." +
                    order.shippingAddress.addressTypeId +
                    ".format",
                  order.shippingAddress
                )
              }}
            </strong>
          </p>
        </template>
      </v-card-text>
    </v-card>
    <PaymentTypeList
      v-if="showPayment"
      class="payment-methods mt-8 mx-4 mx-sm-10"
      :order-id="order.orderId"
      :options="{
        showPaymentMethodImage: true,
        confirm: { color: 'secondary' },
        titleStyle: 'grey--text text--darken-2 text-body-1 mb-2'
      }"
    />

    <v-container
      v-if="!paymentFailed"
      justify="center"
      class="edit-order justify-center d-flex flex-column flex-sm-row"
    >
      <div class="text-center">
        <i18n
          :path="
            'paymentCompleted.deliveryService.' +
              order.shippingAddress.deliveryServiceId +
              '.edit.desc'
          "
          tag="span"
        >
          <a place="orders" href="/profile/orders">{{
            $t("paymentCompleted.edit.link")
          }}</a>
        </i18n>
      </div>
    </v-container>

    <v-container
      class="delivery-reminder text-center mt-5"
      v-if="!paymentFailed"
    >
      <div
        v-html="
          $t(
            `paymentCompleted.deliveryService.${order.shippingAddress.deliveryServiceId}.reminder.timeslot`,
            {
              day: formattedDate,
              time: formattedTime
            }
          )
        "
      />
      <div
        v-html="
          $t(
            `paymentCompleted.deliveryService.${order.shippingAddress.deliveryServiceId}.reminder.address`,
            {
              address: formattedAddress
            }
          )
        "
      />
      <div
        class="mt-10 text-body-2"
        v-html="
          $t(
            `paymentCompleted.deliveryService.${order.shippingAddress.deliveryServiceId}.reminder.caption`,
            {
              address: formattedAddress
            }
          )
        "
      ></div>
    </v-container>

    <!-- <v-container
      class="delivery-reminder text-center text-sm-left mt-5"
      v-else-if="
        !paymentFailed && order.shippingAddress.deliveryServiceId === 2
      "
    >
      <div v-html="$t('paymentCompleted.deliveryService.2.reminder')" />
    </v-container> -->

    <!-- pulsanti -->
    <v-container
      class="buttons d-flex justify-space-between"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'"
    >
      <v-btn
        outlined
        x-large
        color="primary"
        class="mx-2 mx-sm-10 mb-2 mb-md-0"
        v-bind:to="'/'"
      >
        {{ $t("paymentCompleted.button.home") }}
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.lgAndUp && paymentFailed"></v-spacer>
      <v-btn
        color="primary lighten-1"
        x-large
        class="justify-self-md-end my-6 my-md-0 mx-2 mx-sm-10"
        v-if="paymentFailed"
        @click="toggleShowPayment"
      >
        {{ $t("paymentCompleted.button.retry") }}
      </v-btn>

      <v-btn
        v-bind:to="'/profile/orders/' + order.orderId"
        x-large
        color="primary lighten-1"
        class="mx-2 mx-sm-10"
        depressed
      >
        {{ $t("paymentCompleted.button.showOrder") }}
      </v-btn>
    </v-container>
    <v-container>
      <v-img class="mt-5" src="/img_layout/pagamento_buon_fine.jpeg" />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.payment-completed {
  h2 {
    font-size: 31px;
    font-weight: 500;
    &.link {
      a {
        color: $white;
        border-radius: $border-radius-root;
      }
    }
  }
  .ko {
    color: red;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .ok {
    color: var(--v-primary-lighten1);
  }
  .card {
    width: 95%;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: 782px;
    }
    background-color: transparent;
    h3 {
      font-weight: 400;
      color: var(--v-grey-darken2);
      line-height: 33px;
    }
    .v-card__text {
      padding: 0;
    }
  }
  .edit-order {
    font-size: 15px;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: auto;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 13px;
    }
    h4 {
      color: var(--v-primary-lighten1);
    }
    p {
      line-height: 22px;
    }
    a {
      text-decoration: none;
    }
  }
  .delivery-reminder {
    font-size: 20px;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: auto;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 13px;
    }
  }
  .payment-methods {
    background-color: transparent !important;
    ::v-deep.v-expansion-panel {
      background-color: transparent !important;
      padding: 0;
      border-bottom: none;
      border-top: none;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: $white;
        margin-bottom: 5px;
        border-bottom: none;
        border-top: none;
        label {
          display: flex;
          align-items: center;
          &::before {
            top: 8px;
          }
          &::after {
            top: 13px;
          }
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
      }
      .v-expantion-panel {
        background-color: initial;
      }
    }
  }
  .buttons {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-top: 45px;
      margin-bottom: 70px;
    }
  }
  .no-underline {
    text-decoration: none;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .payment-completed {
    .v-card__actions {
      flex-wrap: wrap;
      justify-content: center;
      .v-btn {
        width: 100%;
        margin-top: 5px;
        margin-left: 0px !important;
      }
    }
  }
}
</style>
<script>
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import AnalyticsService from "~/service/analyticsService";
import OrderService from "~/service/orderService";

import categoryMixins from "~/mixins/category";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: { PaymentTypeList, PaymentStepper },
  data() {
    return { order: {}, showPayment: false };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    formattedDate() {
      return `${this.$dayjs(this.order.timeslot.date).format("DD MMMM YYYY")}`;
    },
    formattedTime() {
      return `${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} - ${this.$dayjs(this.order.timeslot.endTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")}`;
    },
    formattedAddress() {
      return `${this.order.shippingAddress.addressName} ${this.order.shippingAddress.address1}, ${this.order.shippingAddress.postalcode} ${this.order.shippingAddress.city} (${this.order.shippingAddress.province})`;
    },
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    }
  },
  methods: {
    ...mapActions({
      addOrderSentToAnalytics: "app/addOrderSentToAnalytics"
    }),
    async fetchOrder(orderId) {
      let vm = this;
      vm.order = await OrderService.getOrder(orderId);
      if (vm.order.orderStatusId == 3) {
        if (!vm.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(vm.order);
            vm.addOrderSentToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    }
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  },
  created() {
    let vm = this;
    vm.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      vm.showPayment = false;
      vm.fetchOrder(order.orderId);
    });
  }
};
</script>
