<template>
  <v-stepper
    v-model="step"
    :alt-labels="$vuetify.breakpoint.smAndDown"
    tile
    elevation="0"
  >
    <v-stepper-header class="flex-nowrap">
      <v-stepper-step
        step="1"
        color="primary lighten-1"
        complete-icon="$check"
        :complete="step > 1"
        @click="navTo('Checkout', true)"
      >
        <div color="font-weight-bold">
          {{ $t("paymentStepper.1") }}
        </div>
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        step="2"
        color="primary lighten-1"
        complete-icon="$check"
        :complete="step > 2"
        @click="navTo('Payment')"
      >
        <span class="font-weight-bold">{{ $t("paymentStepper.2") }}</span>
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        step="3"
        color="primary lighten-1"
        complete-icon="$check"
        :complete="step > 3"
      >
        <span class="font-weight-bold">{{ $t("paymentStepper.3") }}</span>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>
<style>
.v-stepper__header {
  flex-wrap: nowrap;
}
</style>
<script>
export default {
  props: { step: { type: Number, default: 1 } },
  methods: {
    navTo(page, back) {
      if (this.$route.name !== page) {
        if (back) {
          this.$router.back(page);
        } else {
          this.$router.push(page);
        }
      }
    }
  }
};
</script>
