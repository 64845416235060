var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment payment-completed"},[_c('v-container',[_c('PaymentStepper',{staticClass:"mb-2 mt-1 mt-sm-0",attrs:{"step":3}})],1),_c('div',{staticClass:"d-flex align-center flex-column justify-center flex-wrap title-wrap px-2"},[(_vm.paymentFailed)?_c('h2',{staticClass:"mb-10 ko text-center"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.title"))+" ")]):_c('h2',{staticClass:"ok"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.success.title"))+" ")])]),_c('v-card',{staticClass:"card text-center mx-auto",attrs:{"elevation":"0"}},[(_vm.paymentFailed)?_c('v-card-text',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.messageStart"))+" "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(_vm._s(_vm.order.orderId))]),_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.messageEnd"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.retry"))+" ")],1)]):_vm._e(),(!_vm.paymentFailed && _vm.order.orderStatusId === 3)?_c('v-card-text',[(
            _vm.order.shippingAddress.deliveryServiceId === 1 ||
              _vm.order.shippingAddress.deliveryServiceId === 4
          )?[_c('h3',[_vm._v(" "+_vm._s(_vm.$t( `paymentCompleted.deliveryService.${_vm.order.shippingAddress.deliveryServiceId}.title` ))+" ")]),_c('div',{staticClass:"link primary lighten-1 text-h5 font-weight-bold my-7 text-center rounded-sm d-inline-block pa-3",attrs:{"color":"primary"}},[_c('router-link',{staticClass:"no-underline white--text",attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(_vm._s(`N. ${_vm.order.orderId}`))])],1)]:(
            _vm.order.shippingAddress.deliveryServiceId === 2 ||
              _vm.order.shippingAddress.deliveryServiceId === 6
          )?[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.2.title"))+" ")]),_c('div',{staticClass:"link primary lighten-1 text-h5 font-weight-bold my-7 text-center rounded-sm d-inline-block pa-3",attrs:{"color":"primary"}},[_c('router-link',{staticClass:"no-underline white--text",attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(_vm._s(`N. ${_vm.order.orderId}`))])],1),_c('p',{staticClass:"text-h5 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.2.selectedPoint"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.order.shippingAddress.addressTypeId + ".format", _vm.order.shippingAddress ))+" ")])])]:_vm._e()],2):_vm._e()],1),(_vm.showPayment)?_c('PaymentTypeList',{staticClass:"payment-methods mt-8 mx-4 mx-sm-10",attrs:{"order-id":_vm.order.orderId,"options":{
        showPaymentMethodImage: true,
        confirm: { color: 'secondary' },
        titleStyle: 'grey--text text--darken-2 text-body-1 mb-2'
      }}}):_vm._e(),(!_vm.paymentFailed)?_c('v-container',{staticClass:"edit-order justify-center d-flex flex-column flex-sm-row",attrs:{"justify":"center"}},[_c('div',{staticClass:"text-center"},[_c('i18n',{attrs:{"path":'paymentCompleted.deliveryService.' +
              _vm.order.shippingAddress.deliveryServiceId +
              '.edit.desc',"tag":"span"}},[_c('a',{attrs:{"place":"orders","href":"/profile/orders"}},[_vm._v(_vm._s(_vm.$t("paymentCompleted.edit.link")))])])],1)]):_vm._e(),(!_vm.paymentFailed)?_c('v-container',{staticClass:"delivery-reminder text-center mt-5"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            `paymentCompleted.deliveryService.${_vm.order.shippingAddress.deliveryServiceId}.reminder.timeslot`,
            {
              day: _vm.formattedDate,
              time: _vm.formattedTime
            }
          )
        )}}),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            `paymentCompleted.deliveryService.${_vm.order.shippingAddress.deliveryServiceId}.reminder.address`,
            {
              address: _vm.formattedAddress
            }
          )
        )}}),_c('div',{staticClass:"mt-10 text-body-2",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            `paymentCompleted.deliveryService.${_vm.order.shippingAddress.deliveryServiceId}.reminder.caption`,
            {
              address: _vm.formattedAddress
            }
          )
        )}})]):_vm._e(),_c('v-container',{staticClass:"buttons d-flex justify-space-between",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'},[_c('v-btn',{staticClass:"mx-2 mx-sm-10 mb-2 mb-md-0",attrs:{"outlined":"","x-large":"","color":"primary","to":'/'}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.home"))+" ")]),(_vm.$vuetify.breakpoint.lgAndUp && _vm.paymentFailed)?_c('v-spacer'):_vm._e(),(_vm.paymentFailed)?_c('v-btn',{staticClass:"justify-self-md-end my-6 my-md-0 mx-2 mx-sm-10",attrs:{"color":"primary lighten-1","x-large":""},on:{"click":_vm.toggleShowPayment}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.retry"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"mx-2 mx-sm-10",attrs:{"to":'/profile/orders/' + _vm.order.orderId,"x-large":"","color":"primary lighten-1","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.showOrder"))+" ")])],1),_c('v-container',[_c('v-img',{staticClass:"mt-5",attrs:{"src":"/img_layout/pagamento_buon_fine.jpeg"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }